import { useState } from "react";
import "./serverSetup.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ServerSetup: React.FC = () => {
    const [projectDomain, setProjectDomain] = useState<string>("");
    const [projectName, setProjectName] = useState<string>("");
    const [distFolder, setDistFolder] = useState<string>("");
    const [projectType, setProjectType] = useState<number>(1);

    const handleChange = (e: any) => {
        setProjectDomain(e.target.value);
    };
    const handleChange2 = (e: any) => {
        setProjectName(e.target.value);
    };
    const handleChange3 = (e: any) => {
        setDistFolder(e.target.value);
    };
    const handleChange4 = (e: any) => {
        setProjectType(e.target.value);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText("SSH_PASSWORD");
        toast.success("Copied to clipboard!", { autoClose: 500 });
    };


    // eslint-disable-next-line
    const handleDownload = () => {
        let yamlContent = `name: Automated Deployment
on:
  push:
    branches:
      - master
      - main
      - develop
      - dev
    pull_reques t:
      branches:
        - master
        - main
        - develop
        - dev
jobs:
  build:
    runs-on: ubuntu-latest
    steps:
      - name: Checkout repository
        uses: actions/checkout@v1`;
        if (projectType === 1) {
            yamlContent += `
      - name: Install dependencies and build
        run: |
          npm install
          npm run build`;
        }

        yamlContent += `
      - name: Deploy to production
        if: github.ref == 'refs/heads/master' || github.ref == 'refs/heads/main'
        uses: appleboy/ssh-action@master
        with:
          HOST: 193.203.191.131
          USERNAME: ${projectName}
          PORT: 22
          source: "${distFolder}/"
          target: "./htdocs/${projectDomain}/"
          PASSWORD: \${{ secrets.SSH_PASSWORD }}
          strip_components: 1

      - name: Deploy to development environment
        if: github.ref == 'refs/heads/develop' || github.ref == 'refs/heads/dev'
        uses: appleboy/scp-action@master
        with:
          HOST: 193.203.191.131
          USERNAME: ${projectName}-dev
          PORT: 22
          source: "${distFolder}/"
          target: "./htdocs/dev.${projectDomain}/"
          PASSWORD: \${{ secrets.SSH_PASSWORD }}
          strip_components: 1

  send_notification:
    runs-on: ubuntu-latest
    needs: build
    steps:
      - name: Send Teams Notification
        env:
          TEAMS_WEBHOOK_URL: https://sirendesignltd.webhook.office.com/webhookb2/550e58a7-a2b4-4f57-a71c-7f7afd6b6653@bdb867c8-a380-4bee-beb0-6422f2f0d90c/IncomingWebhook/59e2c9bd94bc44aeac74dcfa1f084fba/6d3d2c45-2b8b-404a-8fd8-6ed6c77402ef
        run: |
          if [ "\${{ github.ref }}" = "refs/heads/master" ] || [ "\${{ github.ref }}" = "refs/heads/main" ]; then
            curl -X POST -H 'Content-Type: application/json' -d "{\"text\":\"Deployment completed for repository: main, branch: main. Domain: www.${projectDomain}}" $TEAMS_WEBHOOK_URL
          elif [ "\${{ github.ref }}" = "refs/heads/develop" ] || [ "\${{ github.ref }}" = "refs/heads/dev" ]; then
            curl -X POST -H 'Content-Type: application/json' -d "{\"text\":\"Deployment completed for repository: develop, branch: develop. Domain: https://dev.${projectDomain}}" $TEAMS_WEBHOOK_URL
          fi
        `;

        const element = document.createElement("a");
        const file = new Blob([yamlContent], { type: "text/plain" });
        element.href = URL.createObjectURL(file);
        element.download = "deploy.yaml";
        document.body.appendChild(element);
        element.click();
    };

    return (
        <div className='server-wrapper'>
            <ToastContainer />
            <div className='box-wrapper'>
                <h5>Server Hosting Guide</h5>
                <p>
                    Hostinger Server
                    <br></br>
                    <a href='https://193.203.191.131:8443' target='_blank'>
                        193.203.191.131
                    </a>
                    <br></br>
                    Admin
                </p>
            </div>
            <div className='box-wrapper'>
                <h6>Step 1</h6>
                <p>
                    Setting up a site on the server.
                    <ul>
                        <li>
                            Create a site with the main domain and user, and
                            save the password somewhere to use later.
                        </li>
                        <li>
                            Create a subdomain populate with the same password.
                            Ensure that the subdomain is a <b>dev.</b>{" "}
                            subdomain, and the user has a <b>-dev</b> suffix.
                        </li>
                    </ul>
                    <p>
                        Populate the DNS on{" "}
                        <a href='https://ans.glass/' target='_blank'>
                            <b>ANS</b>
                        </a>{" "}
                        with the new ip 193.203.191.131
                    </p>
                </p>
            </div>
            <div className='box-wrapper'>
                <h6>Step 2</h6>
                <p>
                    Create a repository secret for the server password called:{" "}
                    {""}
                    <b style={{ cursor: "pointer" }} onClick={copyToClipboard}>
                        SSH_PASSWORD{" "}
                    </b>
                    and populate with the server password you created earlier.
                    <br></br>
                    <a href='https://docs.github.com/en/actions/security-guides/using-secrets-in-github-actions#creating-secrets-for-a-repository'>
                        + How to create a github secret
                    </a>
                </p>
            </div>
            <div className='box-wrapper'>
                <h6>Step 3</h6>
                <p>
                    Populate the details for the main domain dev will be handled automatically if you follow the naming convention.
                </p>
                <label>
                    <h6>Project Type:</h6>
                    <select
                        className='select-box'
                        value={projectType}
                        onChange={handleChange4}
                    >
                        <option value={1}>Static with node</option>
                        <option value={2}>Static</option>
                        <option value={3}>Php</option>
                        <option value={4}>WordPress</option>
                    </select>
                </label>
                <label>
                    <h6>Project Name:</h6>
                    <input
                        className='select-box'
                        type='text'
                        value={projectName}
                        onChange={handleChange2}
                    />
                </label>
                <label>
                    <h6>Project Domain:</h6>
                    <input
                        className='select-box'
                        type='text'
                        value={projectDomain}
                        onChange={handleChange}
                    />
                </label>

                <label>
                    <h6>
                        Dist Folder: <br></br>{" "}
                        <span className='sm-txt'>dist for astro</span>
                        <br></br>
                        <span className='sm-txt'>build for react</span>
                        <br></br>
                        <span className='sm-txt'>. if root</span>
                    </h6>
                    <input
                        className='select-box'
                        type='text'
                        value={distFolder}
                        onChange={handleChange3}
                    />
                </label>
            </div>
            <div className="box-wrapper">
                <h6>
                  Step 4
                </h6>
                <p>
                  Create a folder called <b>.github/workflows</b> in the root of your project, and then populate that folder with the <b>.yaml</b> file.
                  <div className='dwn-btn'>
                    <button onClick={handleDownload}>Download YAML File</button>
                </div>
                </p>
            </div>
        </div>
    );
};

export default ServerSetup;
