import React, { useState, DragEvent } from "react";
import { Button, TextField, Stack, ToggleButton } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faPlus } from "@fortawesome/free-solid-svg-icons";
import Post from "../../../types/post.d";
import DropboxService from "../../../data/dropbox/dropboxService";
import dataService from "../../../data/firebase/dataService";
import "firebase/firestore";
import "./upload.scss";

// import { DROPBOX_AUTH } from "../../../utils/API_KEY";

const Upload: React.FC = () => {
    const [title, setTitle] = useState<string>("");

    const [body, setBody] = useState<string>("");

    const [gallery] = useState<File[]>([]);


    const [file, setFile] = useState<File | null>(null);
    const [tags, setTags] = useState<string[]>([]);
    const [newTag, setNewTag] = useState<string>("");

    const [links] = useState<string[]>([]);
    const [currentLink, setCurrentLink] = useState<string>("");

    const [dragIsOver, setDragIsOver] = useState(false);

    if (dragIsOver) {
        console.log('Dragi is over')
    }

    const DROPBOX_AUTH : string = process.env.REACT_APP_DROPBOX_AUTH ?? '';


    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragIsOver(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragIsOver(false);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragIsOver(false);
        const droppedFile = event.dataTransfer.files[0];
        setFile(droppedFile);

        const reader = new FileReader();

        reader.onloadend = () => {
            console.log(reader.result);
        };

        reader.onerror = () => {
            console.error("There was an issue reading the file.");
        };

        reader.readAsDataURL(droppedFile);
    };

    const handleUpload = async () => {
        console.log("Uploading...");

        if (!file) {
            return;
        }

        const dbx = new DropboxService(DROPBOX_AUTH);
        const response = await dbx.uploadImage("", file);
        if (response === null) {
            console.error("Error uploading file");
            return;
        }
        const thumbImage = response;
        let galleryUri: string[] = [];

        for (const image of gallery) {
            galleryUri.push(await dbx.uploadImage("", image));
        }

        console.log("Gallery: " + galleryUri);

        const postObject: Post = {
            title: title,
            body: body,
            uri: thumbImage,
            date: new Date(),
            tags: tags,
            links: links,
            images: galleryUri,
        };
        console.log("Post object:", postObject);
        try {
            await dataService.create(postObject);
            await dataService.updateTags(tags);
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    const appendTag = () => {
        tags.push(newTag.toUpperCase());
        setNewTag("");
    };

    const removeTag = (tag: string) => {
        setTags((prevTags) => prevTags.filter((t) => t !== tag));
    };

    const galleryAdd = (e: File) => {
        gallery.push(e);
    };

    return (
        <div className='hero'>
            <form
                className={"classroot"}
                noValidate
                autoComplete='off'
                onSubmit={async () => {
                    await handleUpload();
                }}
            >
                <div className='form-hero'>
                    <div className='form-wrapper'>
                        <div className='top-half'>
                            <div
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                                className='image-area'
                            >
                                {file ? (
                                    <img src={URL.createObjectURL(file)} alt="url" />
                                ) : (
                                    <div className='thumbnail'>
                                        <FontAwesomeIcon
                                            icon={faCloudArrowUp}
                                            style={{
                                                fontSize: "5rem",
                                                color: "black",
                                            }}
                                        />
                                        <p> Drag & Drop </p>
                                    </div>
                                )}
                            </div>
                            <div className='right-wrapper'>
                                <div>
                                    <div className='form-btn'>
                                        <TextField
                                            className='form-input'
                                            name='title'
                                            label='Title'
                                            value={title}
                                            onChange={(event) =>
                                                setTitle(event.target.value)
                                            }
                                        />
                                    </div>
                                    <Stack
                                        className='padder'
                                        direction='row'
                                        spacing={2}
                                    >
                                        <TextField
                                            value={newTag}
                                            label='Tags'
                                            onChange={(e) =>
                                                setNewTag(e.target.value)
                                            }
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    appendTag();
                                                }
                                            }}
                                        ></TextField>

                                        <Button onClick={appendTag}>
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                style={{
                                                    fontSize: "2rem",
                                                    color: "black",
                                                }}
                                            />
                                        </Button>
                                    </Stack>
                                    {Object.keys(tags).map((tag, index) => {
                                        return tags[index] ? (
                                            <ToggleButton
                                                key={tags[index]}
                                                aria-label={tags[index]}
                                                value={tags[index]}
                                                className='tagButton'
                                                onClick={() =>
                                                    removeTag(tags[index])
                                                }
                                                style={{ borderRadius: "10px" }}
                                            >
                                                {tags[index]}
                                            </ToggleButton>
                                        ) : null;
                                    })}
                                </div>
                                <div>
                                    <div className='form-btn'>
                                        <TextField
                                            className='form-input'
                                            name='links'
                                            label='Links'
                                            value={currentLink}
                                            onChange={(event) =>
                                                setCurrentLink(
                                                    event.target.value
                                                )
                                            }
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    links.push(currentLink);
                                                    setCurrentLink("");
                                                }
                                            }}
                                        />
                                        <Button>
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                style={{
                                                    fontSize: "2rem",
                                                    color: "black",
                                                }}
                                                onClick={() => {
                                                    links.push(currentLink);
                                                    setCurrentLink("");
                                                }}
                                            />
                                        </Button>
                                    </div>
                                    {links.map((link, index) => {
                                        return <div key={index}>{link}</div>;
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='btm-half'>
                            <div className='form-btn'>
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <DatePicker />
                                </LocalizationProvider>
                            </div>
                            <div className='para-wrapper'>
                                <div className='form-wrapper'>
                                    <TextField
                                        className='form-input'
                                        name={`Body'`}
                                        label={`Body`}
                                        multiline
                                        rows={5}
                                        value={body}
                                        onChange={(event) =>
                                            setBody(event.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                <p>Gallery</p>
                                <div style={{display: 'flex'}}>

                                {gallery.map((image) => (
                                    <img src={URL.createObjectURL(image)} style={{width: "45%"}} alt="gallery" />
                                    ))}
                                    </div>
                                <label style={{ cursor: "pointer", border: '1px solid black', width: '128px', height: '128px' }}>
                                    <input
                                        type='file'
                                        style={{
                                            opacity: "0",  
                                            cursor: "pointer",
                                        }}
                                        required
                                        onChange={(e) =>
                                            galleryAdd(e.target.files![0])
                                        }
                                    />
                                    <span></span>
                                </label>
                            </div>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={handleUpload}
                                className='submit-btn'
                                style={{ marginTop: "1rem" }}
                            >
                                <Link to='/feed'>Post</Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Upload;
