import { Input } from '@mui/material';
import './serverHosting.scss';
import { useState } from 'react';

const ServerHosting: React.FC = () => {
    const [framework, setFramework] = useState<string>('');

    return (
        <div className="doc-wrapper">
            <h2>Server Hosting Toolkit</h2>
            <div className='box-wrapper'>
                <p>Dist Folder <span className='greyd'><br/>e.g ./dist ./ ./build</span> </p>
                <Input
                    value={framework}
                    className='framework'
                    onChange={(e) => setFramework(e.target.value)}
                />
            </div>
        </div>
    );
};

export default ServerHosting;
