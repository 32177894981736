export const SITE_TITLE = "Siren";
export const SITE_DESCRIPTION = "Internal Tool for Siren Design";

export const BASE_GPT =
    "Hi Chatgpt I need you to help me define key brand values for a property development I have to create a campaign for. I want you to pretend you are a designer for a design firm in london, and pretend the information i give you is the brief from the client and I want you to create a brand image";

export const BASE_PROMPT =
    'That is the information from the client, from that can you answer these questions make them expanded dont just want you to say back the prompt ive given you. The brackets are for extra content for you dont pass them into the output. 1. What are you branding? 2. Can you summarise it in one buzzword word? 3. Who are your audiences? 4. Based on the previous information can you come up with a brand name for this scheme ( try to incorporate the name of the place or area ). 5. Based on all this information can you create a full proposal (Under 1000chars)? Can you respond in JSON format as a string literal. "values":["question":"","answer":""]';

export const BOTTOM_TEMPLATE: string =
    '<table width="600" border="0" align="center" cellspacing="0" cellpadding="0" style="background-color: rgb(250, 250, 250);table-layout: auto;"> <tbody><tr><td width="600" height="60" align="left" valign="middle"> <div class="ee_editable"><p style="text-align:center;font-size:12px;line-height:16px;font-family:Arial, Helvetica, sans-serif"><br><a href="https://$UNSUB$" style="color:#666">Unsubscribe</a> from further mailings or contact <a href="mailto:marcomms@SEGRO.com" style="color:#666">marcomms@SEGRO.com</a></p></div></td></tr></tbody></table>';
export const TOP_TEMPLATE: string =
    '<table width="600" border="0" align="center" cellpadding="0" cellspacing="0" ee-template-version="8.6" style="table-layout: auto;"><tbody><tr><td><table width="600" border="0" align="center" cellspacing="0" cellpadding="0" style="background-color: rgb(250, 250, 250);table-layout: auto;"><tbody><tr><td width="600" height="50" align="left" valign="middle"> <div class="ee_editable"><p style="text-align:center;font-size:12px;line-height:16px;font-family:Arial, Helvetica, sans-serif">If you are unable to view, please <a href="https://$CANTREAD$" style="color:#666">click here</a></p></div></td></tr></tbody></table>';