import axios from "axios";
import OpenAI from "openai";
import { BASE_GPT, BASE_PROMPT } from "../../utils/consts";
import { ToolkitOutput } from "../../types/gpt";
// import { API_KEY } from "../../utils/API_KEY";
// import { OPEN_API_KEY } from "../../utils/API_KEY";

const API_KEY = process.env.REACT_APP_OPEN_API_KEY;
const OPEN_API_KEY = process.env.REACT_APP_OPEN_API_KEY;

class GptService {
    private openai: OpenAI = new OpenAI({
        apiKey: OPEN_API_KEY,
        dangerouslyAllowBrowser: true,
    });

    async gptPost(input: string): Promise<string> {
        let response = "";
        const stream = await this.openai.chat.completions.create({
            model: "gpt-4",
            messages: [{ role: "user", content: input }],
            stream: true,
        });
        for await (const chunk of stream) {
            response += chunk.choices[0]?.delta?.content || "";
        }
        return response;
    }

    callGpt(input: any): Promise<ToolkitOutput> {
        console.log("Invoked ChatGPT");
        const apiUrl = "https://api.openai.com/v1/chat/completions";
        const data = {
            max_tokens: 500,
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "user",
                    content: `${BASE_GPT} + ${input.values.map(
                        (
                            value: { question: any; answer: any },
                            index: number
                        ) => `${index + 1}. ${value.question} ${value.answer}`
                    )} + ${BASE_PROMPT}`,
                },
            ],
        };

        const headers = {
            Authorization: `Bearer ${API_KEY}`,
        };

        return axios
            .post(apiUrl, data, { headers })
            .then((response) => {
                console.log(
                    "First GPT Response: " +
                        response.data.choices[0].message.content
                );
                return JSON.parse(response.data.choices[0].message.content);
            })
            .catch((error) => {
                console.log({ error });
                return {} as ToolkitOutput;
            });
    }

    async generateImage(res: string) {
        try {
            const imageResponse = await this.openai.images.generate({
                model: "dall-e-3",
                prompt: `a logo (No image of a building) with the brands name or initials somehow incorporated; based on this: ${res}`,
                n: 1,
                size: "1024x1024",
                quality: "hd",
            });

            console.log(imageResponse.data[0].url);

            return imageResponse;
        } catch (err) {
            console.log("Image generation failed");
        }
    }
}

// eslint-disable-next-line
export default new GptService();
