import { useState } from "react";
import "./mailer.scss";
import Switch from "@mui/material/Switch";
import { TOP_TEMPLATE } from "../../../utils/consts";

const Mailer: React.FC = () => {
    const [mailer, setMailer] = useState<string>(" ");
    const [inputValue, setInputValue] = useState<string>("");
    const [inputString, setInputString] = useState<string>("");
    const [isCopied, setCopied] = useState<boolean>(false);
    const [checked, setChecked] = useState(false);

    const createInput = () => {
        setInputString(inputString + inputValue + " ");
        setInputValue("");
    };

    const generateDownload = (content: string) => {
        const a = document.createElement("a");
        const file = new Blob([content], { type: "text/html" });
        a.href = URL.createObjectURL(file);
        a.download = "mailer.html";
        a.click();
    };

    const generateMailer = () => {
        const numStrList: string[] = inputString.split(" ");
        numStrList.pop();
        console.log(numStrList);

        try {
            const numList: number[] = numStrList.map((numStr) =>
                parseFloat(numStr)
            );
            const htmlRows: string[] = [];
            const maxColumns: number = Math.max(...numList);

            for (const num of numList) {
                const numColumns: number =
                    num % 1 === 0 ? num : Math.floor(num) + 1;
                const colspan: number = Math.floor(maxColumns / numColumns);
                console.log(colspan);
                let htmlCells: string = "";

                for (let i = 1; i <= numColumns; i++) {
                    htmlCells += `<td colspan=${colspan}><img src="./${i}" alt="image" style="display: flex;" width="${
                        600 / numColumns
                    }"/></td>`;
                }
                const htmlRow: string = `<tr>${htmlCells}</tr>`;
                htmlRows.push(htmlRow);
            }

            const tableHtml: string = `${
                checked ? TOP_TEMPLATE : ""
            }<table width="600" cellspacing="0" cellpadding="0" align="center">${htmlRows.join(
                ""
            )}</table>${checked ? TOP_TEMPLATE : ""}`;
            if (checked) {
                generateDownload(tableHtml);
                setMailer("File has been downloaded.");
            }else{
                setMailer(tableHtml);
            }
        } catch (error) {
            console.log(
                "Invalid input. Please enter numbers separated by spaces."
            );
        }
    };

    const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(mailer);
        setCopied(true);
        setTimeout(() => setCopied(false), 350);
    };

    return (
        <div className='hero'>
            <div className='wrapper'>
                <h1>Mailer Html Generator</h1>
                <p className='bodyCopy'>
                    Enter the number of rows you want for each column seperated
                    by a comma.
                </p>
                <p>{inputString}</p>
                <div className='input_container'>
                    <p className='btnCap'>Number of Rows: </p>
                    <div className='button_container'>
                        <input
                            className='inpField'
                            type='number'
                            value={inputValue}
                            onChange={(e) =>
                                setInputValue(e.target.value.toString())
                            }
                            onKeyDown={(e) => {
                                if (e.key === "Enter") createInput();
                            }}
                        />
                        <button className='addBtn' onClick={createInput}>
                            Add Column
                        </button>
                    </div>
                </div>

                <div className='flex' style={{ marginTop: "20px" }}>
                    {" "}
                    Dotdigital mailer:
                    <Switch
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={handleChecked}
                        checked={checked}
                        size='small'
                    />
                </div>
                <button className='genBtn' onClick={generateMailer}>
                    Generate
                </button>
                <p>{mailer}</p>
                {mailer !== (' ' || 'File has been downloaded.')? (
                    <button onClick={copyToClipboard}>
                        {!isCopied ? "Copy" : "Copied"}
                    </button>
                ) : null}
            </div>
        </div>
    );
};

export default Mailer;
